







































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class BulkDeletePicklPopup extends Vue {
  @Prop() popupId!: string
  @Prop() selectedPicklIds!: any

  deletePickl () {
    this.$http
      .post('pickls/bulk-delete', { 'picklIds': this.selectedPicklIds }, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        let DeletePicklCloseButtonPopup: HTMLElement = this.$refs.DeletePicklCloseButtonPopup as HTMLElement
        this.$notify({ type: 'success', text: 'Pickls has been deleted successfully!' })
        this.$emit('bulkPicklDeleted')
        DeletePicklCloseButtonPopup.click()
      })
  }
}
